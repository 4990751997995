import {useState, useCallback} from 'react'

interface UseClipboardResult {
  copyToClipboard: (text: string) => Promise<boolean>
  isCopied: boolean
}

export function useClipboard(resetInterval = 2000): UseClipboardResult {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = useCallback(
    async (text: string) => {
      if (!navigator.clipboard) {
        console.warn('Clipboard API not available')
        return false
      }

      try {
        await navigator.clipboard.writeText(text)
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), resetInterval)
        return true
      } catch (error) {
        console.error('Failed to copy text: ', error)
        setIsCopied(false)
        return false
      }
    },
    [resetInterval],
  )

  return {copyToClipboard, isCopied}
}
