import type {SVGAttributes} from 'react'
import cx from 'classnames'

export function Check({className, ...rest}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      strokeWidth={1.5}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={cx('size-5', className)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.5 12.75 6 6 9-13.5"
      />
    </svg>
  )
}
