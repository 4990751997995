import i18n, {type InitOptions} from 'i18next'
import {initReactI18next} from 'react-i18next'
import backend from 'i18next-http-backend'

declare global {
  var IS_DESKTOP_CLIENT: boolean
}

if (!globalThis.IS_DESKTOP_CLIENT) {
  i18n.use(backend)
}

const i18nconfig: InitOptions = {
  lng: 'en', // if you're using a language detector, do not define the lng option
  fallbackLng: 'en',
  backend: {
    // for all available options read the backend's repository readme file
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
}

declare global {
  interface Window {
    translations: {
      getTranslations: () => Promise<{
        [lng: string]: {[ns: string]: {[key: string]: string}}
      }>
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nconfig)

if (globalThis.IS_DESKTOP_CLIENT) {
  // getTranslations is exposed in the desktop client preload script.
  window.translations?.getTranslations().then((resources) => {
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({...i18nconfig, resources})
  })
} else {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(i18nconfig)
}

export {i18n}
