import {useMutation, type MutationOptions} from '@tanstack/react-query'

import {fetcher, queryClient} from '~/utilities/queries'

async function markAsRead(emailId: string) {
  return await fetcher(`/api/emails/${emailId}/mark_as_read`, {method: 'POST'})
}

type UseMarkAsReadArgs = {
  email?: Email
  onMutateCallback?: () => void
}

export function useMarkAsRead({
  email,
  onMutateCallback,
  ...rest
}: UseMarkAsReadArgs & Omit<MutationOptions, 'mutationFn' | 'onMutate'>) {
  const emailId = `${email?.id}`

  return useMutation({
    mutationFn: async () => await markAsRead(emailId),
    onMutate: () => {
      queryClient.setQueryData(
        ['email', emailId],
        ({email}: {email: Email}) => ({
          email: {
            ...email,
            read: true,
          },
        }),
      )
      onMutateCallback?.()
    },
    throwOnError: true,
    ...rest,
  })
}
