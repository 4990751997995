import {AnimatePresence} from 'framer-motion'

import {Email} from './Email'
import {EmailGroup} from './EmailGroup'
import type {GroupedEmails} from './hooks/useEmails'

type EmailsProps = {
  groupedEmails: GroupedEmails
  flattenedEmails?: Email[]
}

export function Emails({groupedEmails, flattenedEmails}: EmailsProps) {
  return (
    <AnimatePresence>
      {flattenedEmails && flattenedEmails.length > 0
        ? flattenedEmails.map((email) => <Email key={email.id} {...email} />)
        : Object.values(groupedEmails).map((emails) =>
            emails.length === 1 ? (
              <Email key={emails[0]!.id} {...emails[0]!} />
            ) : (
              <EmailGroup
                key={emails[0]!.sender.id}
                sender={emails[0]!.sender}
                emails={emails}
              />
            ),
          )}
    </AnimatePresence>
  )
}
