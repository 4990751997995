export function Logo({className}: {className?: string}) {
  return (
    <svg
      width="75"
      height="20"
      viewBox="0 0 75 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M63.5308 19V15.928L69.1468 10.072H63.7468V7.04803H73.9468V10L68.1628 15.952H74.1868V19H63.5308Z"
        fill="#0815FF"
      />
      <path
        d="M61.7505 12.976C61.7505 13.808 61.6225 14.608 61.3665 15.376C61.1105 16.144 60.7345 16.824 60.2385 17.416C59.7585 17.992 59.1665 18.456 58.4625 18.808C57.7585 19.16 56.9585 19.336 56.0625 19.336C55.2625 19.336 54.5025 19.176 53.7825 18.856C53.0785 18.52 52.5345 18.048 52.1505 17.44H52.1025V19H48.4785V0.856018H52.4145V8.32002H52.4625C52.7985 7.92002 53.2705 7.55202 53.8785 7.21602C54.4865 6.88002 55.2305 6.71202 56.1105 6.71202C56.9745 6.71202 57.7505 6.88002 58.4385 7.21602C59.1425 7.55202 59.7345 8.00802 60.2145 8.58402C60.7105 9.16002 61.0865 9.83202 61.3425 10.6C61.6145 11.352 61.7505 12.144 61.7505 12.976ZM57.9585 12.976C57.9585 12.592 57.8945 12.216 57.7665 11.848C57.6545 11.48 57.4785 11.16 57.2385 10.888C56.9985 10.6 56.7025 10.368 56.3505 10.192C55.9985 10.016 55.5905 9.92802 55.1265 9.92802C54.6785 9.92802 54.2785 10.016 53.9265 10.192C53.5745 10.368 53.2705 10.6 53.0145 10.888C52.7745 11.176 52.5825 11.504 52.4385 11.872C52.3105 12.24 52.2465 12.616 52.2465 13C52.2465 13.384 52.3105 13.76 52.4385 14.128C52.5825 14.496 52.7745 14.824 53.0145 15.112C53.2705 15.4 53.5745 15.632 53.9265 15.808C54.2785 15.984 54.6785 16.072 55.1265 16.072C55.5905 16.072 55.9985 15.984 56.3505 15.808C56.7025 15.632 56.9985 15.4 57.2385 15.112C57.4785 14.824 57.6545 14.496 57.7665 14.128C57.8945 13.744 57.9585 13.36 57.9585 12.976Z"
        fill="#0815FF"
      />
      <path
        d="M41.5603 19V17.344H41.5123C41.3683 17.616 41.1763 17.872 40.9363 18.112C40.7123 18.352 40.4403 18.56 40.1203 18.736C39.8163 18.912 39.4723 19.056 39.0883 19.168C38.7203 19.28 38.3283 19.336 37.9123 19.336C37.1123 19.336 36.4323 19.192 35.8723 18.904C35.3283 18.616 34.8803 18.248 34.5283 17.8C34.1923 17.336 33.9443 16.816 33.7843 16.24C33.6403 15.648 33.5683 15.048 33.5683 14.44V7.04803H37.5283V13.528C37.5283 13.864 37.5523 14.184 37.6003 14.488C37.6483 14.792 37.7363 15.064 37.8643 15.304C38.0083 15.544 38.1923 15.736 38.4163 15.88C38.6403 16.008 38.9363 16.072 39.3043 16.072C39.9923 16.072 40.5123 15.816 40.8643 15.304C41.2323 14.792 41.4163 14.192 41.4163 13.504V7.04803H45.3523V19H41.5603Z"
        fill="#0815FF"
      />
      <path
        d="M29.4 10.576C29.08 10.272 28.704 10.016 28.272 9.808C27.84 9.6 27.384 9.496 26.904 9.496C26.536 9.496 26.192 9.568 25.872 9.712C25.568 9.856 25.416 10.104 25.416 10.456C25.416 10.792 25.584 11.032 25.92 11.176C26.272 11.32 26.832 11.48 27.6 11.656C28.048 11.752 28.496 11.888 28.944 12.064C29.408 12.24 29.824 12.472 30.192 12.76C30.56 13.048 30.856 13.4 31.08 13.816C31.304 14.232 31.416 14.728 31.416 15.304C31.416 16.056 31.264 16.696 30.96 17.224C30.656 17.736 30.256 18.152 29.76 18.472C29.28 18.792 28.736 19.024 28.128 19.168C27.52 19.312 26.912 19.384 26.304 19.384C25.328 19.384 24.368 19.232 23.424 18.928C22.496 18.608 21.72 18.144 21.096 17.536L23.304 15.208C23.656 15.592 24.088 15.912 24.6 16.168C25.112 16.424 25.656 16.552 26.232 16.552C26.552 16.552 26.864 16.48 27.168 16.336C27.488 16.176 27.648 15.904 27.648 15.52C27.648 15.152 27.456 14.88 27.072 14.704C26.688 14.528 26.088 14.344 25.272 14.152C24.856 14.056 24.44 13.928 24.024 13.768C23.608 13.608 23.232 13.392 22.896 13.12C22.576 12.848 22.312 12.512 22.104 12.112C21.896 11.712 21.792 11.232 21.792 10.672C21.792 9.952 21.944 9.344 22.248 8.848C22.552 8.336 22.944 7.92 23.424 7.6C23.904 7.28 24.432 7.048 25.008 6.904C25.6 6.744 26.184 6.664 26.76 6.664C27.656 6.664 28.528 6.808 29.376 7.096C30.24 7.368 30.968 7.784 31.56 8.344L29.4 10.576Z"
        fill="#0815FF"
      />
      <path
        d="M19.3564 19L22.5484 0.856018H26.4604L23.2444 19H19.3564Z"
        fill="#FF00D6"
      />
      <path
        d="M13.4782 19L16.6702 0.856018H20.5822L17.3662 19H13.4782Z"
        fill="#0815FF"
      />
      <path
        d="M11.904 10.552C11.904 10.776 11.888 11.016 11.856 11.272C11.84 11.528 11.808 11.776 11.76 12.016L10.944 16.672C10.864 17.088 10.8 17.512 10.752 17.944C10.72 18.376 10.704 18.728 10.704 19H7.296C7.296 18.824 7.304 18.624 7.32 18.4C7.336 18.16 7.36 17.936 7.392 17.728H7.344C6.464 18.768 5.248 19.288 3.696 19.288C3.28 19.288 2.848 19.232 2.4 19.12C1.968 19.024 1.568 18.856 1.2 18.616C0.848 18.36 0.56 18.032 0.336 17.632C0.112 17.216 0 16.712 0 16.12C0 15.16 0.24 14.376 0.72 13.768C1.2 13.16 1.824 12.688 2.592 12.352C3.376 12.016 4.256 11.784 5.232 11.656C6.224 11.528 7.216 11.464 8.208 11.464C8.24 11.32 8.256 11.208 8.256 11.128C8.256 11.048 8.256 10.976 8.256 10.912C8.256 10.448 8.088 10.112 7.752 9.90399C7.432 9.67999 6.976 9.56799 6.384 9.56799C5.84 9.56799 5.288 9.67199 4.728 9.87999C4.184 10.088 3.688 10.36 3.24 10.696L1.656 8.48799C2.376 7.91199 3.2 7.47199 4.128 7.16799C5.072 6.84799 6 6.68799 6.912 6.68799C7.632 6.68799 8.296 6.76799 8.904 6.92799C9.512 7.07199 10.032 7.30399 10.464 7.62399C10.912 7.94399 11.264 8.34399 11.52 8.82399C11.776 9.30399 11.904 9.87999 11.904 10.552ZM7.656 13.768C7.176 13.768 6.688 13.792 6.192 13.84C5.696 13.872 5.248 13.952 4.848 14.08C4.464 14.208 4.144 14.384 3.888 14.608C3.632 14.832 3.504 15.128 3.504 15.496C3.504 15.976 3.664 16.304 3.984 16.48C4.32 16.64 4.688 16.72 5.088 16.72C5.824 16.72 6.44 16.496 6.936 16.048C7.432 15.584 7.744 15.016 7.872 14.344L7.992 13.768H7.656Z"
        fill="#0815FF"
      />
    </svg>
  )
}
