import {updateHomeFeedCache} from '~/components/Email/cache'
import type {GroupedEmails} from '~/components/Email/hooks/useEmails'
import {queryClient} from '~/utilities/queries'

function getHomeEmailsToUpdate(readEmailIds: string[]) {
  const emailsToUpdate = queryClient.getQueriesData<{
    email: Email
  }>({
    predicate: (query) =>
      query.queryKey[0] === 'email' &&
      readEmailIds.includes(query.queryKey[1] as string),
  })

  return emailsToUpdate.map(([_, cachedEmail]) => cachedEmail?.email)
}

export function setReadEmailsInCacheOnNavigate(
  readEmailIds: string[],
  unreadEmailIds: string[],
) {
  const emailsToMarkAsReadUnread = getHomeEmailsToUpdate([
    ...readEmailIds,
    ...unreadEmailIds,
  ])

  if (emailsToMarkAsReadUnread.length === 0) return

  for (const email of emailsToMarkAsReadUnread) {
    if (!email) continue

    queryClient.setQueryData(
      ['homeEmails'],
      (oldData: GroupedEmails | undefined) => {
        const read = readEmailIds.includes(`${email.id}`)
          ? true
          : unreadEmailIds.includes(`${email.id}`)
            ? false
            : email.read

        const updatedData = updateHomeFeedCache(
          {
            ...email,
            read,
          },
          oldData,
        )

        return updatedData
      },
    )
  }
}
