import cx from 'classnames'

import {Tooltip} from '~/components/Tooltip/Tooltip'
import {useClipboard} from '~/hooks/useClipboard'

import {getURLHostname} from '../../utils'
import {Check} from '~/components/Icons/Check'

type SmartActionProps = {
  smartAction: SmartAction
  className?: string
  hideTooltip?: boolean
}

export function SmartAction({
  smartAction,
  className,
  hideTooltip,
}: SmartActionProps) {
  const {copyToClipboard, isCopied} = useClipboard()

  switch (smartAction.purpose) {
    case 'code':
      return (
        <Tooltip content={`Copy ${smartAction.content} code to clipboard`}>
          <button
            type="button"
            key={smartAction.id}
            className="flex flex-wrap items-center text-sm font-medium hover:bg-surface h-full rounded-md px-2 cursor-pointer"
            onClick={() => copyToClipboard(smartAction.content)}
          >
            {smartAction.label}:{' '}
            <span className="ml-1 font-semibold">{smartAction.content}</span>
            {isCopied && (
              <Check className="w-4 h-4 ml-2 text-brand" strokeWidth={2} />
            )}
          </button>
        </Tooltip>
      )
    case 'interact': {
      const hostname = getURLHostname(smartAction.content)
      const content = (
        <a
          key={smartAction.id}
          data-smart-action-id={smartAction.id}
          className={cx(
            'flex flex-wrap items-center text-sm font-medium hover:bg-surface h-full rounded-md px-2 cursor-pointer',
            className,
          )}
          href={smartAction.content}
          target="_blank"
        >
          <span className="text-ellipsis whitespace-nowrap overflow-hidden">
            {smartAction.label}
          </span>
          {hideTooltip && (
            <small className="w-full block mt-0.5 -mb-0.5 text-surface-secondary text-ellipsis whitespace-nowrap overflow-hidden">
              External link to:{' '}
              <span className="underline underline-offset-1">{hostname}</span>
            </small>
          )}
        </a>
      )

      return hideTooltip ? (
        content
      ) : (
        <Tooltip
          content={
            <>
              External link to:{' '}
              <span className="underline underline-offset-1">{hostname}</span>
            </>
          }
        >
          {content}
        </Tooltip>
      )
    }
    default:
      return null
  }
}
