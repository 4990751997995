import {useState, useRef, useLayoutEffect} from 'react'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'

import {Email, SubIcon} from './Email'
import {Button} from '../Button/Button'
import {useMarkEmailsAsRead} from '~/hooks/emails/useMarkEmailsAsRead'

type EmailGroupProps = {
  sender: Sub
  emails: Email[]
}

const DEFAULT_COLLAPSED_HEIGHT = 300

export function EmailGroup({sender, emails}: EmailGroupProps) {
  const [open, setOpen] = useState(false)
  const [height, setHeight] = useState(DEFAULT_COLLAPSED_HEIGHT)
  const contentRef = useRef<HTMLDivElement>(null)

  // In a group, the first email is guaranteed to exist.
  const firstEmail = emails[0]!
  const restEmails = emails.slice(1, emails.length)
  const allEmailsRead = emails.every(({read}) => read)

  const {mutate: markEmailsAsRead, isSuccess} = useMarkEmailsAsRead(emails, {
    onSuccess: () => setTimeout(() => setOpen(false), 50),
  })

  useLayoutEffect(() => {
    if (contentRef.current) {
      const newHeight = open
        ? contentRef.current.scrollHeight
        : DEFAULT_COLLAPSED_HEIGHT
      setHeight(newHeight)
    }
  }, [open, allEmailsRead, isSuccess])

  return (
    <section className="w-full bg-white rounded-xl overflow-hidden ring-2 ring-gray-400 mb-5 last:mb-0">
      <header
        className="relative w-full flex justify-between items-center p-4 overflow-hidden"
        data-email-group-target="header"
      >
        <SubIcon
          src={sender.logo ?? ''}
          className="absolute left-0 top-0 z-10 w-96 h-96 blur-2xl opacity-15"
        />
        <div className="relative flex items-center gap-2">
          <SubIcon src={sender.logo ?? ''} />
          <Link to={`/subs/${sender.slug}`}>
            <h3 className="text-sm font-semibold mt-0.5">
              {sender.name} <span className="mx-1 text-text-subdued">•</span>{' '}
              {!allEmailsRead && !isSuccess ? (
                <>You've got {emails.length} emails</>
              ) : (
                <>All emails read</>
              )}
            </h3>
          </Link>
        </div>
        <div className="flex gap-2">
          {!allEmailsRead && !isSuccess && (
            <Button
              type="button"
              size="sm"
              variant="primary"
              onClick={() => markEmailsAsRead()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="currentColor"
                className="btn-icon size-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
              Mark all as read
            </Button>
          )}
          <Button
            type="button"
            size="sm"
            variant="ghost"
            onClick={() => setOpen(!open)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="btn-icon size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
              />
            </svg>
            <span>{open ? 'Collapse' : 'Expand'}</span>
          </Button>
        </div>
      </header>
      {!allEmailsRead ||
        (isSuccess && (
          <span className="block w-full h-0.5 bg-gradient-to-r from-[#A3A3A3] to-[#EEEEEE]"></span>
        ))}
      <motion.div
        ref={contentRef}
        className="w-full bg-surface-ternary relative overflow-hidden"
        initial={{height: allEmailsRead ? 0 : DEFAULT_COLLAPSED_HEIGHT}}
        animate={{
          height: (!open && !allEmailsRead && !isSuccess) || open ? height : 0,
        }}
        transition={{duration: 0.3}}
      >
        <div className="p-4">
          <Email
            {...firstEmail}
            showIcon={false}
            showSender={false}
            showMarkAsRead={false}
            className="shadow-2xl row-start-1 col-start-1 mb-4"
          />
          {restEmails.map((email) => (
            <Email
              showIcon={false}
              showSender={false}
              showMarkAsRead={false}
              key={email.id}
              {...email}
              className="shadow-2xl mb-4 last:mb-0"
            />
          ))}
        </div>
      </motion.div>
    </section>
  )
}
