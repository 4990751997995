import {formatDistance} from 'date-fns'
import DOMPurify from 'dompurify'

export function emailCreatedAgo(email: Email) {
  return formatDistance(new Date(email.createdAt), new Date(), {
    addSuffix: true,
  })
}

export function emailPath(prefix: string, email: Email) {
  return prefix === '' ? `/emails/${email.id}` : `${prefix}/emails/${email.id}`
}

export function getURLHostname(url: string) {
  try {
    return new URL(url).hostname
  } catch (error) {
    return url
  }
}

function removeExternalResources(html: string) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  // Remove external stylesheets
  doc
    .querySelectorAll('link[rel="stylesheet"]')
    .forEach((link) => link.remove())

  // Remove external scripts (should already be handled by DOMPurify, but just in case)
  doc.querySelectorAll('script').forEach((script) => script.remove())

  // Remove external fonts
  doc
    .querySelectorAll('link[rel="preconnect"], link[rel="dns-prefetch"]')
    .forEach((link) => link.remove())

  return doc.body.innerHTML
}

function removeTrackingPixels(html: string) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  let removedImagesCount = 0

  // Remove 1x1 pixel images
  doc.querySelectorAll('img').forEach((img) => {
    if (img.width <= 1 && img.height <= 1) {
      img.remove()
      removedImagesCount++
    }
  })

  return {
    html: doc.body.innerHTML,
    removedImagesCount,
  }
}

export function renderSafeEmail(html: string) {
  let safeHtml = DOMPurify.sanitize(html, {
    FORBID_TAGS: ['script', 'iframe', 'form'],
    FORBID_ATTR: ['onerror', 'onload', 'onclick'],
  })

  safeHtml = removeExternalResources(safeHtml)
  const {html: sanitizedHtml} = removeTrackingPixels(safeHtml)

  return sanitizedHtml
}
