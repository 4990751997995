import {
  useState,
  createContext,
  useContext,
  type SetStateAction,
  type Dispatch,
} from 'react'
import {Outlet} from 'react-router-dom'
import {Provider as TooltipProvider} from '@radix-ui/react-tooltip'

import {AuthProvider} from '~/providers/Auth'

export type FeedContext = {
  enableCustomScrollbar: boolean
  isEmpty?: boolean
  readEmailIds: string[]
  unreadEmailIds: string[]
}

const feedContext = createContext<
  [FeedContext, Dispatch<SetStateAction<FeedContext>>]
>([
  {enableCustomScrollbar: true, readEmailIds: [], unreadEmailIds: []},
  () => {},
])

const {Provider} = feedContext

export function useFeedContext() {
  return useContext(feedContext)
}

export function Dashboard() {
  const [feedCtx, setFeedCtx] = useState<FeedContext>({
    enableCustomScrollbar: true,
    readEmailIds: [],
    unreadEmailIds: [],
  })

  return (
    <AuthProvider>
      <TooltipProvider disableHoverableContent delayDuration={400}>
        <Provider value={[feedCtx, setFeedCtx]}>
          <Outlet />
        </Provider>
      </TooltipProvider>
    </AuthProvider>
  )
}
