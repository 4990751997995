import {useEffect, useMemo, useRef, useState} from 'react'
import cx from 'classnames'

import {Bolt} from '~/components/Icons/Bolt'
import {Tooltip} from '~/components/Tooltip/Tooltip'

import {SmartAction} from './SmartAction'
import {Dialog} from '~/components/Dialog/Dialog'

const SMART_ACTIONS_PADDINGS = 24

type SmartActionsProps = {
  smartActions: SmartAction[]
  floats?: boolean
}

export function SmartActions({smartActions, floats = true}: SmartActionsProps) {
  const smartActionsWrapper = useRef<HTMLDivElement>(null)
  const [smartActionsToHide, setSmartActionsToHide] = useState(0)
  const [smartActionsModalVisible, setSmartActionsModalVisible] =
    useState(false)
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          const smartActionsWidth = Array.from(entry.target.children || [])
            .filter((elem) =>
              Boolean(elem.getAttribute('data-smart-action-id')),
            )
            .reduce((acc, elem) => acc + elem.clientWidth, 0)
          const smartActionsContentTooBig =
            smartActionsWidth > (entry.target.clientWidth ?? 0)

          if (smartActionsContentTooBig) {
            const calcSmartActionsToHide = Math.ceil(
              smartActionsWidth /
                ((entry.target.clientWidth ?? 0) - SMART_ACTIONS_PADDINGS),
            )

            if (calcSmartActionsToHide !== smartActionsToHide) {
              setSmartActionsToHide(calcSmartActionsToHide)
            }
          }
        }
      }),
    [setSmartActionsToHide],
  )

  useEffect(() => {
    if (smartActionsWrapper.current) {
      resizeObserver.observe(smartActionsWrapper.current)
    }

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <div
      className={cx(
        'flex items-center gap-1 bg-white ring-2 ring-surface-ternary rounded-lg px-2 py-1.5 h-10 shadow-t-xl',
        {
          'absolute bottom-2 left-2 right-2': floats,
          'relative w-full': !floats,
        },
      )}
    >
      <Tooltip content="Smart Actions">
        <span className="size-7 flex items-center justify-center rounded-md bg-surface hover:bg-surface-ternary">
          <Bolt strokeWidth={2} className="text-brand-secondary" />
        </span>
      </Tooltip>
      <div
        ref={smartActionsWrapper}
        className="w-full flex h-full overflow-hidden"
      >
        {smartActions.map((smartAction, idx) => (
          <SmartAction
            key={smartAction.id}
            smartAction={smartAction}
            className={cx({
              'invisible pointer-events-none':
                idx >= smartActions.length - smartActionsToHide,
            })}
          />
        ))}
      </div>
      {smartActionsToHide > 0 && (
        <button
          type="button"
          className="flex items-center text-sm font-medium bg-surface hover:bg-surface-ternary h-full rounded-md px-2 cursor-pointer whitespace-nowrap"
          onClick={() => setSmartActionsModalVisible(true)}
        >
          {smartActionsToHide} more
        </button>
      )}
      <Dialog
        open={smartActionsModalVisible}
        onOpenChange={() => setSmartActionsModalVisible(false)}
      >
        <Dialog.Title>Smart Actions</Dialog.Title>
        <div className="px-2">
          {smartActions.map((smartAction) => (
            <SmartAction
              hideTooltip
              key={smartAction.id}
              smartAction={smartAction}
              className="py-2"
            />
          ))}
        </div>
      </Dialog>
    </div>
  )
}
