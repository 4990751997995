import {produce} from 'immer'

import type {GroupedEmails} from './hooks/useEmails'

export function updateHomeFeedCache(email: Email, oldData?: GroupedEmails) {
  try {
    if (!oldData) return oldData

    return produce(oldData, (draft) => {
      const subKey = `sub_${email.sender.id}`

      if (draft[subKey]) {
        const emailIndex = draft[subKey].findIndex((e) => e.id === email.id)

        if (emailIndex !== -1) {
          draft[subKey][emailIndex] = email
        }
      } else {
        draft[subKey] = [email]
      }
    })
  } catch (error) {
    return oldData
  }
}
