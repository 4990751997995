import type {ReactNode} from 'react'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import type {
  TooltipProps as RadixTooltipProps,
  TooltipContentProps,
} from '@radix-ui/react-tooltip'

type TooltipProps = RadixTooltipProps & {
  content?: ReactNode
  contentProps?: TooltipContentProps
  className?: string
}

export function Tooltip({
  children,
  content,
  contentProps,
  className,
  ...rest
}: TooltipProps) {
  return (
    <RadixTooltip.Root {...rest}>
      <RadixTooltip.Trigger asChild className={className}>
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className="z-50 bg-black text-white text-xs font-medium pt-1.5 pb-1 px-2 rounded border border-gray-800 shadow pointer-events-none max-w-64 overflow-hidden text-ellipsis whitespace-nowrap"
          sideOffset={5}
          {...contentProps}
        >
          {content}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  )
}
