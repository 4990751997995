import {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'

import 'overlayscrollbars/overlayscrollbars.css'

import {App} from '../app'

const root = createRoot(document.getElementById('root') as HTMLDivElement)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
