import type {SVGAttributes} from 'react'
import cx from 'classnames'

export function Bolt({className, ...rest}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      strokeWidth={1.5}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={cx('size-5', className)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
      />
    </svg>
  )
}
