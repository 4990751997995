import {QueryClient} from '@tanstack/react-query'
import {Headers, ofetch} from 'ofetch'

import {AUTH_TOKEN} from '~/utilities/constants'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})

export const fetcher = ofetch.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://app.allsubz.com'
      : 'http://app.allsubz.test',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
  async onRequest({options}) {
    const headers = {
      ...options.headers,
      Authorization: `Bearer ${JSON.parse(localStorage.getItem(AUTH_TOKEN) ?? '')}`,
    }

    options.headers = new Headers(headers)
  },
})
