import {useEffect} from 'react'
import {useQuery} from '@tanstack/react-query'
import set from 'lodash/set'

import {
  flattenGroupedEmails,
  filterFeedEmails,
  type GroupedEmails,
} from '~/components/Email/hooks/useEmails'
import {useFeedContext} from '~/routes/Dashboard/Dashboard'
import {fetcher, queryClient} from '~/utilities/queries'
import {useAuthContext} from '~/providers/Auth'

import {FeedEmptyState} from '../../components/Feed/FeedEmptyState'
import {Emails} from '~/components/Email/Emails'

export async function getEmails() {
  const emails = await fetcher<GroupedEmails>('/api/emails')

  const flatEmails = flattenGroupedEmails(emails)
  const subsIds = Object.keys(emails).map((key) =>
    parseInt(key.split('_')[1]!, 10),
  )

  for (const email of flatEmails) {
    queryClient.setQueryData(['email', email.id.toString()], {email})
  }

  for (const subId of subsIds) {
    const subEmails = flatEmails.filter((email) => email.sender.id === subId)
    const sub = subEmails[0]?.sender

    const subExists = queryClient.getQueryData<Sub>(['sub', sub?.slug])

    if (!subExists) {
      queryClient.setQueryData(['sub', sub?.slug], {...sub, emails: subEmails})
    }
  }

  // This will definitely need to be optimized!
  const topicEmails = Object.entries(emails).reduce(
    (acc, [_, emails]) => {
      for (const email of emails) {
        for (const topic of email.sender.topics) {
          set(acc, `${topic.name}.sub_${email.sender.id}`, [
            ...(acc[topic.name]?.[`sub_${email.sender.id}`] || []),
            email,
          ])
        }
      }

      return acc
    },
    {} as {[key: string]: GroupedEmails},
  )

  for (const [topicName, groupedEmails] of Object.entries(topicEmails)) {
    const topicExists = queryClient.getQueryData<Tag>(['topic', topicName])

    if (!topicExists) {
      queryClient.setQueryData(['topic', topicName], {emails: groupedEmails})
    }
  }

  return emails
}

export function Home() {
  const {data} = useQuery<GroupedEmails>({
    queryKey: ['homeEmails'],
  })
  const [_, setFeedContext] = useFeedContext()
  const {currentUser} = useAuthContext()

  const filteredFeedEmails = filterFeedEmails(
    currentUser!.settings.feedView,
    data,
  )
  const flattenedEmails = flattenGroupedEmails(filteredFeedEmails)

  useEffect(() => {
    setFeedContext((value) => ({
      ...value,
      enableCustomScrollbar: flattenedEmails.length !== 0,
    }))
  }, [data, currentUser!.settings, flattenedEmails.length])

  if (flattenedEmails.length === 0) return <FeedEmptyState title="All done." />

  return (
    <Emails
      groupedEmails={filteredFeedEmails}
      flattenedEmails={
        currentUser?.settings.feedGrouping === 'flat'
          ? flattenedEmails
          : undefined
      }
    />
  )
}
