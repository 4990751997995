import type {SVGAttributes} from 'react'
import cx from 'classnames'

export function BarsThree({className, ...rest}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      strokeWidth={1.5}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={cx('size-5', className)}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
      />
    </svg>
  )
}
