import {Logo} from '../Logo/Logo'

import {useIsDesktopClient} from '~/hooks/useIsDesktopClient'

export function DesktopAppTitleBar() {
  const isDesktopClient = useIsDesktopClient()

  if (!isDesktopClient) return null

  return (
    <div className="w-full h-7 bg-surface-ternary flex items-center justify-center app-region-drag px-2">
      <Logo className="w-12" />
    </div>
  )
}
