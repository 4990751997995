import type {ReactNode} from 'react'
import {createContext, useContext} from 'react'
import * as RadixDialog from '@radix-ui/react-dialog'
import type {DialogProps as RadixDialogProps} from '@radix-ui/react-dialog'

import {Cross} from '../Icons/Cross'

type CommonProps = {
  children?: ReactNode
}

type DialogProps = CommonProps &
  RadixDialogProps & {
    onClose?: () => void
  }

const dialogContext = createContext<Partial<DialogProps>>({})
const {Provider} = dialogContext

export function Dialog({
  open,
  children,
  modal,
  onClose,
  onOpenChange,
}: DialogProps) {
  return (
    <RadixDialog.Root modal={modal} open={open} onOpenChange={onOpenChange}>
      <Provider value={{open, onClose}}>
        <RadixDialog.Portal>
          <RadixDialog.Overlay className="fixed top-0 left-0 w-full h-full z-40 bg-white opacity-85" />
          <RadixDialog.Content className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 pb-2 bg-white shadow-lg w-96 rounded-lg overflow-hidden border border-surface-ternary">
            {children}
          </RadixDialog.Content>
        </RadixDialog.Portal>
      </Provider>
    </RadixDialog.Root>
  )
}

Dialog.Title = function ({children}: CommonProps) {
  const {onClose} = useContext(dialogContext)

  return (
    <RadixDialog.Title className="flex items-center px-3 py-3 mb-2 font-medium border-b border-b-surface">
      {children}
      <RadixDialog.Close asChild>
        <button
          type="button"
          className="ml-auto size-6 bg-surface rounded flex items-center justify-center outline-none hover:bg-surface-ternary focus:ring-2 focus:ring-offset-1 focus:ring-brand/45"
          aria-label="Close"
          onClick={onClose}
        >
          <Cross />
        </button>
      </RadixDialog.Close>
    </RadixDialog.Title>
  )
}

Dialog.Description = function ({children}: CommonProps) {
  return <RadixDialog.Description>{children}</RadixDialog.Description>
}

Dialog.Footer = function ({children}: CommonProps) {
  return <div>{children}</div>
}
